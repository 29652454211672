import Icons from "icons/sidebar";

const index = [
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard",
  },
  {
    title: "Manage Models",
    Icon: Icons.PeopleIcon,
    path: "/models",
  },
  {
    title: "Manage Subscriptions",
    Icon: Icons.AccountBalanceWalletIcon,
    path: "/subscriptions",
  },
  {
    title: "Audit Logs",
    Icon: Icons.VerifiedUserIcon,
    path: "/audit-logs",
  },
];

export default index;

import axios from "axios";

//export const BASE_URL = "http://localhost:5000";
export const BASE_URL = "https://api-agency.goloti.com/api";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";

import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// user profile
const AgencyProfile = Loadable(lazy(() => import("./pages/AgencyProfile")));

// Models
const Models = Loadable(
  lazy(() => import("./pages/Models/Models"))
);
const ViewModel = Loadable(
  lazy(() => import("./pages/Models/ViewModel"))
);
const CreateModel = Loadable(
  lazy(() => import("./pages/Models/CreateModel"))
);
const AssociateModel = Loadable(
  lazy(() => import("./pages/Models/AssociateModel"))
);

// Subscriptions
const Subscription = Loadable(
  lazy(() => import("./pages/subscription/Subscription"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "profile",
    element: (
      <AuthGuard>
        <DashboardLayout>
        <AgencyProfile />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
        <DashboardSaaS />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "models",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Models />,
      },
      {
        path: "associate",
        element: <AssociateModel />,
      },
      {
        path: "add",
        element: <CreateModel />,
      },
      {
        path: "view",
        element: <AgencyProfile />,
      },
      {
        path: ":id",
        element: <ViewModel />,
      },
    ],
  },
  {
    path: "subscriptions",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Subscription />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;

import {
  GridView as UserGridIcon,
  Login as LoginIcon,
  PersonAdd as AddUserIcon,
} from "@mui/icons-material";
import InsightsIcon from "@mui/icons-material/Insights";
import LinkIcon from "@mui/icons-material/Link";
import PeopleIcon from "@mui/icons-material/People";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AddIcon from '@mui/icons-material/Add';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DashboardIcon from "./Dashboard";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginIcon,
  AddUserIcon,
  SessionsIcon,
  UserGridIcon,
  DashboardIcon,
  UserProfileIcon,
  UserManagementIcon,
  LinkIcon,
  InsightsIcon,
  TravelExploreIcon,
  PeopleIcon,
  AddIcon,
  CorporateFareIcon,
  SubscriptionsIcon,
  VerifiedUserIcon,
  AccountBalanceWalletIcon,
};
